import React from 'react';
import '../../styles/pages/tech-section.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Books from '../../images/library.jpg';

// Components
import Layout from '../layout';
// import PostCard from '../post-card';
import Header from '../header';
import Sidebar from '../sidebar';

const TechSection = () => {
  return (
    <Layout>
      <Container>
        <Header title="Technique Section" />
        <div className="TechSec__banner">
          <img src={Books} width="100%" style={{ opacity: '.8' }} alt="books" />
        </div>
        <Row>
          <Col xs={{ order: 2, span: 12 }} md={{ order: 1, span: 8 }} lg={{ order: 1, span: 9 }}>
            <Header title="Recommended Posts" viewmore path="/tech-info/tech-section/posts" />
            {/* <Row> <PostCard md={12} lg={6} />
              <PostCard md={12} lg={6} /> </Row> */}
            <p>We are still working on it!</p>
            <Header title="Recommended Videos" viewmore path="/tech-info/tech-section/videos" />
            {/* <Row>
              
              <Col sm={12} md={12} lg={6}>
                <div style={{ width: '100%', height: '200px', background: 'lightgrey', marginBottom: '20px' }}>
                  Videos
                </div>
              </Col>
              <Col sm={12} md={12} lg={6}>
                <div style={{ width: '100%', height: '200px', background: 'lightgrey' }}>Videos</div>
              </Col>
            </Row> */}
            <p>We are still working on it!</p>
          </Col>
          <Col
            xs={{ order: 1, span: 12 }}
            md={{ order: 2, span: 4 }}
            lg={{ order: 2, span: 3 }}
            style={{ marginTop: '15px' }}
          >
            <Sidebar />
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default TechSection;
